import {UAParser} from "ua-parser-js";

/**
 * Adds device information to all forms on the page.
 *
 * @param {Object} deviceInfo - The device information object.
 */
function addDeviceInfoToForms(deviceInfo) {
    const forms = document.querySelectorAll("form");
    forms.forEach(form => {
        let input = form.querySelector("input[name=\"deviceInfo\"]");
        if (!input) {
            input = document.createElement("input");
            input.type = "hidden";
            input.name = "deviceInfo";
            form.appendChild(input);
        }
        input.value = JSON.stringify(deviceInfo);
    });
}

/**
 * Initializes the UAParser and returns the device information.
 *
 * @returns {Object} The device information object.
 */
function getDeviceInfo() {
    const parser = new UAParser();
    let deviceInfo = parser.getResult();
    deviceInfo.device.type = deviceInfo.device.type || "desktop";
    deviceInfo.device.viewWidth = window.innerWidth;
    if (deviceInfo.device.viewWidth < 768) {
        deviceInfo.device.type = "mobile";
    }

    return deviceInfo;
}

/**
 * Fetches the IP address from an external service.
 *
 * @returns {Promise<string>} The IP address as a string.
 * @throws {Error} Throws an error if the IP address cannot be fetched.
 */
async function fetchIpAddress() {
    const response = await fetch("https://api.ipify.org?format=json");
    if (!response.ok) {
        throw new Error(`Failed to fetch IP address: ${response.status}`);
    }
    const data = await response.json();
    return data.ip;
}

/**
 * Retrieves device information, including the IP address, from cache if available.
 * If not available in cache, it will fetch the IP address and cache the result.
 * It also posts the device info to "/api/domain-names-verify".
 *
 * @returns {Promise<Object>} The device information object with IP address included.
 */
async function getCachedDeviceInfo() {
    let deviceInfo = JSON.parse(sessionStorage.getItem("deviceInfo"));

    if (!deviceInfo) {
        deviceInfo = getDeviceInfo();

        try {
            deviceInfo.ipAddress = await fetchIpAddress();
        } catch (error) {
            console.error(error.message);
            deviceInfo.ipAddress = "unknown";
        }

        const csrfToken = document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content");

        const payload = {
            deviceInfo: JSON.stringify(deviceInfo),
            name: window.location.hostname,
        };

        try {
            await $.ajax({
                url: "/api/domain-names/verify",
                type: "POST",
                data: JSON.stringify(payload),
                contentType: "application/json",
                headers: {
                    "X-CSRF-TOKEN": csrfToken,
                },
            });
        } catch (error) {
            console.error("Failed to post device info:", error);
        }

        sessionStorage.setItem("deviceInfo", JSON.stringify(deviceInfo));
    }

    return deviceInfo;
}

/** Main function to initialize device information and add it to forms. */
async function initDeviceInfo() {
    const deviceInfo = await getCachedDeviceInfo();
    addDeviceInfoToForms(deviceInfo);
}

/* Execute the main function on load */
initDeviceInfo();

export {getCachedDeviceInfo, addDeviceInfoToForms};
